import React from 'react';

const Images = ({
  type = 'width',
  url,
  className = '',
  alt = '',
  width = '',
  height = '',
  role = '',
}) => {
  if (!url) {
    return (
      <picture className={className}>
        <img
          src='/img/menu/accueil.png'
          alt={alt}
          loading='lazy'
          decoding='async'
          width={width}
          height={height}
        />
      </picture>
    );
  }

  return type === 'width' ? (
    <picture className={className}>
      <source
        type='image/webp'
        srcSet={`${url}?fm=webp&w=500 500w,
                 ${url}?fm=webp&=750 750w,
                 ${url}?fm=webp&w=1080 1080w,
                 ${url}?fm=webp&w=1200 1200w,
                 ${url}?fm=webp&w=1920 1920w,
        `}
      />

      <img
        src={`${url}`}
        srcSet={`${url}?w=500 500w,
                 ${url}?w=750 750w,
                 ${url}?w=1080 1080w,
                 ${url}?w=1200 1200w,
                 ${url}?w=1920 1920w,
        `}
        alt={alt}
        loading='lazy'
        decoding='async'
        width={width}
        height={height}
      />
    </picture>
  ) : (
    <picture className={className}>
      <source
        type='image/webp'
        srcSet={`${url}?fm=webp&w=500 1x,
                 ${url}?fm=webp&w=1200 2x,
        `}
      />

      <img
        src={`${url}`}
        srcSet={`${url}?w=500 1x,
                 ${url}?w=1200 2x,
        `}
        alt={alt}
        loading='lazy'
        decoding='async'
        width={width}
        height={height}
      />
    </picture>
  );
};

export default Images;
