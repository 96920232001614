import React, { useEffect, useRef } from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import AnimatedMenu from './AnimatedMenu/AnimatedMenu';
import layout from '../../public/layout.json';
import useStore from '../store/Store';

const Layout = ({ pathRoot, children }) => {
  const close = useStore((state) => state.close);

  useEffect(() => {
    close();
  }, []);

  return (
    <>
      <AnimatedMenu links={layout.navigationCollection.items} />
      <Header pathRoot={pathRoot} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
