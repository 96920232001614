import React, { useEffect, useRef, useState, useMemo } from 'react';
import MenuAnim from '../Utils/menuAnim';
import styles from './AnimatedMenu.module.scss';
import Image from '../Images/Images';
import { gsap } from 'gsap';
import { CLEAN_STR } from '../../utils/string';
import Link from 'next/link';
import { useRouter } from 'next/router';
import ETourismButton from '../ETourismButton/ETourismButton';
import useStore from '../../store/Store';
import BackgroundTransitions from '../Utils/BackgroundTransitions/BackgroundTransitions';

const AnimatedMenu = ({ links }) => {
  const close = useStore((state) => state.close);
  const timeline = useMemo(
    () => gsap.timeline({ paused: true, onReverseComplete: () => close() }),
    []
  );
  const menuRef = useRef(null);
  const router = useRouter();

  const getDefaultImage = () => {
    let image = null;

    for (const link of links) {
      if (router.pathname === link.url) {
        return (image = 'c-' + CLEAN_STR(link.title));
      }
    }

    return (image = 'c-accueil');
  };

  const [defaultImage, setDefaultImage] = useState('');
  const [displayImage, setDisplayImage] = useState(null);
  const [previousDisplayImage, setPreviousDisplayImage] = useState(null);

  const hideImage = (el) => {
    const image = document.querySelector(`.${el}`);
    gsap.to(image, {
      opacity: 0,
      duration: 0.5,
    });
  };

  const showImage = (el) => {
    const image = document.querySelector(`.${el}`);
    gsap.to(image, {
      opacity: 1,
      duration: 0.5,
    });
  };

  const changeRoute = (href) => {
    const tl = gsap.timeline({
      onComplete: () => {
        router.push(href);
      },
    });

    tl.to(
      '#btn-menu-close',
      {
        opacity: 0,
        duration: 0.5,
      },
      0
    )
      .to(
        '#image-container',
        {
          opacity: 0,
          duration: 0.5,
        },
        0
      )
      .to(
        '#policies-links a',
        {
          opacity: 0,
          duration: 0.2,
          stagger: 0.2,
        },
        0
      )
      .to('.link', {
        x: '-100%',
        duration: 0.3,
        stagger: -0.2,
      });
  };

  const handleClick = (e, url) => {
    e.preventDefault();

    if (router.route === url) return;

    hideImage();
    changeRoute(url);
  };

  // Hide Menu after bg animation out is completed
  const closeMenu = () => {
    timeline.reverse();
  };

  // Display Menu content when bg animation in is completed
  const openMenu = () => {
    timeline.play();
  };

  // Define Timeline
  useEffect(() => {
    timeline
      .to('#btn-menu-close', {
        opacity: 1,
        duration: 0.5,
      })
      .to('.link', {
        x: 0,
        duration: 0.3,
        stagger: 0.2,
      })
      .to('#policies-links a', {
        opacity: 0.5,
        duration: 0.5,
        stagger: 0.2,
      })
      .to('#image-container', {
        opacity: 1,
        duration: 0.5,
      });

    return () => {
      timeline.kill();
    };
  }, []);

  useEffect(() => {
    const image = getDefaultImage();
    setDefaultImage(image);
  }, []);

  useEffect(() => {
    if (!defaultImage) return;

    setDisplayImage(defaultImage);
  }, [defaultImage]);

  useEffect(() => {
    if (!displayImage && !previousDisplayImage) return;
    if (displayImage !== previousDisplayImage) hideImage(previousDisplayImage);

    showImage(displayImage);
    setPreviousDisplayImage(displayImage);
  }, [displayImage]);

  return (
    <div className={styles.AnimatedMenu} id='menu' ref={menuRef}>
      <BackgroundTransitions
        onInComplete={openMenu}
        onOutComplete={() => (menuRef.current.style.display = 'none')}
      />

      <div className={styles.AnimatedMenu__header} id='btn-menu-close'>
        <img
          src='/img/svg/icn-close.svg'
          alt='Fermer'
          width='50'
          height='50'
          onClick={closeMenu}
        />
      </div>
      <div className={styles.AnimatedMenu__content}>
        <div className={styles.AnimatedMenu__content__links}>
          {links.map((link) => (
            <Link href={link.url} key={'link-' + CLEAN_STR(link.title)}>
              <a
                id={'t-' + link.title}
                onMouseEnter={() =>
                  setDisplayImage('c-' + CLEAN_STR(link.title))
                }
                onMouseLeave={() => setDisplayImage(defaultImage)}
                className={`link ${
                  (link.url === '/' && router.pathname === '/') ||
                  (link.url !== '/' && router.pathname.indexOf(link.url) === 0)
                    ? 'selected'
                    : ''
                }`}
                onClick={(e) => handleClick(e, link.url)}
              >
                {link.title}
                <div className={styles.AnimatedMenu__content__links__bg}></div>
              </a>
            </Link>
          ))}

          <div
            id='policies-links'
            className={styles.AnimatedMenu__content__links__around}
          >
            <Link href={'/mentions-legales'}>
              <a onClick={(e) => handleClick(e, '/mentions-legales')}>
                Mentions légales
              </a>
            </Link>
            <Link href={'/politique-confidentialite'}>
              <a onClick={(e) => handleClick(e, '/politique-confidentialite')}>
                Politique relative aux cookies
              </a>
            </Link>
          </div>
        </div>

        <div
          id='image-container'
          className={styles.AnimatedMenu__content__image}
        >
          {links.map((link) => {
            return (
              <Image
                key={link.title}
                className={'c-' + CLEAN_STR(link.title)}
                url={link.image.url}
                alt={link.title}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AnimatedMenu;
