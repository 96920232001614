/* eslint-disable react/display-name */
import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

export const getClassOptions = ({ paragraphClassName }) => {
  return {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClassName}>{children}</p>
      ),
    },
  };
};

export const RichText = ({ content, paragraphClassName = null }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClassName}>{children}</p>
      ),
      [INLINES.HYPERLINK]: ({ data }, children) => {
        return (
          <a
            href={data.uri}
            target={`${
              data.uri.indexOf('akarah.com') > -1 ? '_self' : '_blank'
            }`}
            rel={`${
              data.uri.indexOf('akarah.com') > -1 ? '' : 'noopener noreferrer'
            }`}
          >
            {children}
          </a>
        );
      },
    },
  };

  return documentToReactComponents(content, options);
};
