import React from 'react';
import FilArianne from '../../components/FilArianne/FilArianne';
import Hamburger from '../../components/Hamburger/Hamburger';
import styles from './Header.module.scss';

const Header = ({ pathRoot }) => {
  return (
    <>
      <div id='nav' className={styles.Nav}></div>
      <header className={styles.Header}>
        <nav>
          <FilArianne root={`/ ${pathRoot}`} />
          <Hamburger />
        </nav>
      </header>
    </>
  );
};

export default Header;
