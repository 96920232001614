import React from 'react';
import styles from './Button.module.scss';
import Link from 'next/link';

const Button = ({ children, link = '/', gradient = false, id = null }) => {
  if (!gradient) {
    return (
      <Link href={link}>
        <a className={`${styles.button}`} id={id}>
          {children}
        </a>
      </Link>
    );
  }

  return (
    <Link href={link}>
      <a className={`${styles.GradientButton}`} id={id}>
        <p>{children}</p>
        <img
          src='/img/svg/icn-arrow-right.svg'
          alt=''
          role='presentation'
          width='15'
          height='15'
        />
      </a>
    </Link>
  );
};

export default Button;
