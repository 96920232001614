import React, { useRef, useEffect } from 'react';
import styles from './Footer.module.scss';
import { gsap } from 'gsap/dist/gsap';
import Button from '../Button/Button';
import layout from '../../../public/layout.json';
import { RichText } from '../../scripts/contentful';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const Footer = () => {
  const footersRef = useRef(null);
  const footerRef = useRef(null);

  // Display footer navbar
  useEffect(() => {
    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 800px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: footerRef.current,
            scrub: true,
            start: `top ${25 + 20 + 32}`,
            end: `top ${25 + 20}`,
          },
        });

        tl.to(
          '#t-menuHamburger div span',
          { background: '#fff', duration: 1 },
          0
        )
          .to('#t-menuHamburger p', { color: '#fff', duration: 1 }, 0)
          .to('#filArianne', { opacity: 0, duration: 1 }, 0)
          .set('#filArianne', { display: 'none' }, 1);
      },

      // small
      '(min-width: 600px) and (max-width: 799px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: footerRef.current,
            scrub: true,
            start: `top ${20 + 20 + 32}`,
            end: `top ${20 + 20}`,
          },
        });

        tl.to(
          '#t-menuHamburger div span',
          { background: '#fff', duration: 1 },
          0
        )
          .to('#t-menuHamburger p', { color: '#fff', duration: 1 }, 0)
          .to('#filArianne', { opacity: 0, duration: 1 }, 0)
          .set('#filArianne', { display: 'none' }, 1);
      },

      // mobile
      '(max-width: 599px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: footerRef.current,
            scrub: true,
            start: `top ${15 + 15 + 32}`,
            end: `top ${15 + 15}`,
          },
        });

        tl.to(
          '#t-menuHamburger div span',
          { background: '#fff', duration: 1 },
          0
        )
          .to('#t-menuHamburger p', { color: '#fff', duration: 1 }, 0)
          .to('#filArianne', { opacity: 0, duration: 1 }, 0)
          .set('#filArianne', { display: 'none' }, 1);
      },
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 1000);
  }, []);

  const year = new Date().getFullYear();

  return (
    <footer ref={footersRef}>
      <div className={styles.Footer} ref={footerRef}>
        <div className={styles.Footer__mask}></div>
        <div className={styles.Footer__content}>
          <h2>ABECEDAIRE</h2>
          <div className={styles.Footer__content__smallDivider}></div>
          <RichText content={layout.descriptionFooter.json} />
          <Button link={'/studio'}>Découvrir le studio</Button>
          <div className={styles.Footer__content__divider}></div>
          <address>
            <div className={styles.Footer__content__info}>
              <img
                width='30'
                height='30'
                src='/img/svg/footer/icn-phone.svg'
                alt='Téléphone'
              />
              <a href={`tel:${layout.phone}`}>{layout.phone}</a>
            </div>
            <div className={styles.Footer__content__info}>
              <img
                width='30'
                height='30'
                src='/img/svg/footer/icn-clock.svg'
                alt="Horaires d'ouverture"
              />
              <p>{layout.workHours}</p>
            </div>
            <div className={styles.Footer__content__info}>
              <img
                width='30'
                height='30'
                src='/img/svg/footer/icn-card.svg'
                alt='Adresse'
              />
              <a
                href='https://goo.gl/maps/vUPgFuDscPHegs4o8'
                target='_BLANK'
                rel='noreferrer'
              >
                {layout.address}
              </a>
            </div>
          </address>
        </div>
      </div>
      <div className={styles.subFooter}>
        <video
          src='/videos/Anim-bg-symbol.webm'
          autoPlay={true}
          muted={true}
          loop
        ></video>

        <div>
          <img
            width='50'
            height='50'
            src='/img/icon-abecedaire-color.png'
            alt='Logo ABECEDAIRE'
            loading='lazy'
          />
          <p>ABECEDAIRE © {year}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
