import React from 'react';
import styles from './FilArianne.module.scss';
import Link from 'next/link';

const FilArianne = ({ root = '', rootUrl = '', path = '' }) => {
  return (
    <div id='filArianne' className={styles.FilArianne}>
      <Link href={'/'}>
        <a className={styles.FilArianne_logo}>
          <img
            id='logo'
            src='/img/icon-abecedaire-color.png'
            alt='Logo ABECEDAIRE'
          />
        </a>
      </Link>
      <p>
        {path !== '' && rootUrl !== '' ? (
          <Link href={rootUrl}>
            <a>{root}</a>
          </Link>
        ) : (
          root
        )}
      </p>
      <p id='path'>{path}</p>
    </div>
  );
};

export default FilArianne;
