import React from 'react';
import styles from './Hamburger.module.scss';
import useStore from '../../store/Store';

const Hamburger = () => {
  const open = useStore((state) => state.open);

  const openMenu = () => {
    const menu = document.querySelector('#menu');
    menu.style.display = 'block';
    open();
  };

  return (
    <div id='t-menuHamburger' className={styles.Hamburger} onClick={openMenu}>
      <p>Menu</p>
      <div className={styles.Hamburger__icon}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default Hamburger;
